import React, {useEffect, useState} from 'react';
import './News.css';

function SectionSecond() {
    const [news, setNews] = useState([]);

    const lang = localStorage.getItem("language");

    const [apiMonth, setApiMonth] = useState("");
    const [apiYear, setApiYear] = useState("");

    const monthNames = date => {
        if (date === '10') {
            date = lang === "am" ? "Հոկտեմբեր" : "October";
        } else if (date === '11') {
            date = lang === "am" ? "Նոյեմբեր" : "November";
        } else if (date === '12') {
            date = lang === "am" ? "Դեկտեմբեր" : "December";
        } else if (date === '01') {
            date = lang === "am" ? "Հունվար" : "January";
        } else if (date === '02') {
            date = lang === "am" ? "Փետրվար" : "February";
        } else if (date === '03') {
            date = lang === "am" ? "Մարտ" : "March";
        } else if (date === '04') {
            date = lang === "am" ? "Ապրիլ" : "April";
        } else if (date === '05') {
            date = lang === "am" ? "Մայիս" : "May";
        } else if (date === '06') {
            date = lang === "am" ? "Հունիս" : "June";
        } else if (date === '07') {
            date = lang === "am" ? "Հուլիս" : "July";
        } else if (date === '08') {
            date = lang === "am" ? "Օգոստոս" : "August";
        } else if (date === '09') {
            date = lang === "am" ? "Սեպտեմբեր" : "September";
        }

        return date;
    };

    //Api Call
    useEffect(function () {
        let apiUrl = window.location.href;

        var a = "";

        if (apiUrl.split("month=")[1] && apiUrl.split("&year=")[apiUrl.split("&year=").length - 1]) {
            a = `?month=${apiUrl.split("month=")[1].split("&year")[0]}&year=${apiUrl.split("&year=")[apiUrl.split("&year=").length - 1]}`
        }

        fetch(`${process.env.REACT_APP_API_URL}/api/blog/all/${lang}${a}`)
            .then(res => res.json())
            .then(res => {
                setApiMonth(res.blog[0].date.split(".")[0]);
                setApiYear(res.blog[0].date.split(".")[2]);
                setNews(res.blog)
            })
            .catch(err => console.log(err))
    }, []);

    function SliceText({ item, lang }) {
        // Extract text content from HTML
        const textContent = new DOMParser().parseFromString(
            item["text_" + lang],
            'text/html'
        ).body.textContent;

        // Slice the text to 800 words
        const slicedText = textContent.slice(0, 800) + "...";

        return <div dangerouslySetInnerHTML={{__html: slicedText}}/>;
    }

    return (
        <div className="news_container container">

            <h1 className="page-title container">{lang === "am" ? "Նորություններ" : "News"}</h1>

            <div className="row">

                <div className="card-group">

                    {news.length ? news.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, key) => {
                        return (

                            <div className=" col-12 card-container" key={key}>
                                <a className="card item"
                                   href={`/${!item["url_" + lang] ? "am" : lang}/${lang === "am" || !item["url_" + lang] ? "նորություններ" : "news"}/${item["url_" + lang] ? item["url_" + lang] : item["url_am"]}`}>

                                    <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                        <img className={"card-img-top"} src={process.env.REACT_APP_IMAGE_URL + "/blog/" + item.pic} alt={"news"}/>
                                    </div>
                                    <div className="card-body col-lg-7 col-md-12 col-sm-12 col-12">
                                        <div>
                                            <p className="card-text">{item.date}</p>
                                        </div>
                                        <h5 className="card-title title">
                                            {item["title_" + lang]}
                                        </h5>
                                        <div>
                                            <p className="card-text news_paragraph" >
                                                <SliceText item={item} lang={lang} />
                                                {/*{ReactHtmlParser(item["text_" + lang].slice(0, 800) + (ReactHtmlParser(item["text_" + lang].length >= 800 ? "..." : "")))}*/}
                                            </p>
                                        </div>

                                    </div>
                                </a>
                            </div>

                        );
                    }) : null}
                </div>

            </div>
        </div>
    )
}

export default SectionSecond;
