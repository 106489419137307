import {useState} from 'react';
import {sanitize} from '../../../utils/miscellaneous';
import Loading from '../../loading';
import {Container, Row, Col} from 'reactstrap';

const NewsletterForm = ({status, message, onValidated}) => {

    const [error, setError] = useState(null);
    const [email, setEmail] = useState(null);
    const lang = localStorage.getItem("language");


    const sending = lang === "am" ? "Ուղղարկվում է․․․" : "Sending...";
    const notify = lang === "am" ? "Շնորհակալություն բաժանորդագրվելու համար" : "Thank you for subscribing!";
    const validationNotify = lang === "am" ? "Խնդրում ենք լրացնել ճիշտ էլ․ հասցե" : "Please enter a valid email address";
    const errorNotify = lang === "am" ? "Էլփոստի հասցեն պետք է պարունակի մեկ @" : "An email address must contain a single @";


    switch (message) {

        case "0 - An email address must contain a single @":
            message = errorNotify;
            break;

        case "Thank you for subscribing!":
            message = notify;
            break;
    }

    /**
     * Handle form submit.
     *
     * @return {{value}|*|boolean|null}
     */
    const handleFormSubmit = () => {

        setError(null);

        if (!email) {
            setError(validationNotify);
            return null;
        }

        const isFormValidated = onValidated({EMAIL: email});

        // On success return true
        return email && email.indexOf("@") > -1 && isFormValidated;
    };

    /**
     * Handle Input Key Event.
     *
     * @param event
     */
    const handleInputKeyEvent = (event) => {
        setError(null);
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            handleFormSubmit();
        }
    };

    /**
     * Extract message from string.
     *
     * @param {String} message
     * @return {null|*}
     */
    const getMessage = (message) => {
        if (!message) {
            return null;
        }
        const result = message?.split('-') ?? null;
        if ("0" !== result?.[0]?.trim()) {
            return sanitize(message);
        }
        let formattedMessage = result?.[1]?.trim() ?? null;

        return formattedMessage ? sanitize(formattedMessage) : null;
    };

    return (
        <div className={"subscribe"}>
            <Container className="subscribeContainer">
                <Row>
                    <Col>

                        <div className="subscribe_form">
                            <div>
                                <input
                                    onChange={(event) => setEmail(event?.target?.value ?? '')}
                                    type="email"
                                    placeholder={lang === "am" ? "Էլ․փոստ" : "Email"}
                                    onKeyUp={(event) => handleInputKeyEvent(event)}
                                />
                            </div>


                            <div className="subscribeBtn-container">
                                <button className="subscribeBtn"
                                        onClick={handleFormSubmit}>
                                    {lang === "am" ? "Բաժանորդագրվել" : "Subscribe"}
                                </button>
                            </div>

                            <div className="min-h-42px">
                                {'sending' === status ?
                                    <Loading showSpinner message={sending} contentColorClass="text-white"
                                             hasVisibilityToggle={false}/> : null}
                                {'error' === status || error ? (
                                    <div
                                        className="text-red-700 pt-2"
                                        dangerouslySetInnerHTML={{__html: error || getMessage(message)}}
                                    />
                                ) : null}
                                {'success' === status && 'error' !== status && !error && (
                                    <div className="text-green-200 font-bold pt-2"
                                         dangerouslySetInnerHTML={{__html: sanitize(message)}}/>
                                )}
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
};

export default NewsletterForm