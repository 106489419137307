import React, {useState, useEffect} from 'react'
import './SME.css'
import {getUrlPath} from '../../helpers/getUrlPath'
import ReactHtmlParser from 'react-html-parser';

export default function MissionAndStructure(props) {
    const [apiData, setApiData] = useState([]);

    const lang = localStorage.getItem("language");

    //Api Call
    useEffect(function () {
        fetch(process.env.REACT_APP_API_URL + "/api/pmdz/mission/" + lang)
            .then(res => res.json())
            .then(res => setApiData(res.mission))
    }, [lang]);

    getUrlPath(props)


    return (
        <>

            <div className="mission container">
                <div className="section">

                    <h1 className="page-title">   {lang === "am" ? "ՓՄՁ Զարգացման Խորհուրդ" : "SME DEVELOPMENT COUNCIL"}</h1>

                    {ReactHtmlParser(apiData["text_" + lang])}

                    {apiData["pic_" + lang] ?
                        <img src={process.env.REACT_APP_IMAGE_URL + "/pmdz_mission/" + apiData["pic_" + lang]}
                             className="structure__image"
                        /> : null}
                </div>
            </div>
        </>
    )
}
