import React, {} from 'react'
import {Col} from 'reactstrap';
import './Reforms.css'

import ReactHtmlParser from 'react-html-parser';

export default class ImplementedProgramsAndReforms extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            apiData: []
        }
    }

    componentDidMount() {
        let array = [];

        fetch(process.env.REACT_APP_API_URL + "/api/programs/past/" + localStorage.getItem("language"))
            .then(res => res.json())
            .then(res => {
                res.map(orders => {
                    orders.map(order => {
                        if (order.field === "Հարկային ոլորտ") {
                            array[0] = orders;
                        } else if (order.field === "Մաքսային ոլորտ") {
                            array[1] = orders;
                        } else if (order.field === "Բիզնեսի գրանցում և լուծարում") {
                            array[2] = orders;
                        } else if (order.field === "Այլ հարցեր") {
                            array[3] = orders;
                        }
                    })
                });

                this.setState({apiData: array})
            })
    }

    render() {
        const lang = localStorage.getItem("language");

        const checkReformHeader = (txt, lang) => {
            if (txt === "Հարկային ոլորտ" && lang === "en") {
                txt = "Tax area";
            } else if (txt === "Մաքսային ոլորտ" && lang === "en") {
                txt = "Customs area";
            } else if (txt === "Բիզնեսի գրանցում և լուծարում" && lang === "en") {
                txt = "Business registration and liquidation area";
            } else if (txt === "Այլ հարցեր" && lang === "en") {
                txt = "Other";
            }

            if (txt === "Հարկային" && lang === "am") {
                txt = "Հարկային ոլորտ";
            } else if (txt === "Մաքսային" && lang === "am") {
                txt = "Մաքսային ոլորտ";
            }

            return txt;
        };

        return (
            <div className={'reformsContainer'}>

                <div className=' section'>

                    <h1 className="page-title container">{lang === "am" ? "Իրականացված և ընթացիկ բարեփոխումներ" : "Implemented and ongoing reforms"}</h1>

                    {this.state.apiData ? this.state.apiData.map(function (fields, key) {
                        return (
                            <React.Fragment key={key}>

                                {fields.map((text, textKey) => {
                                    return (
                                        <div className={`chess-list`} key={textKey}>
                                            <Col className={"container"}>
                                                <h6 className='protocolText'>{ReactHtmlParser(text["text_" + lang])}</h6>
                                            </Col>

                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        )
                    }) : null}
                </div>

            </div>
        )
    }
}