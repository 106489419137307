import React, {useState, useEffect} from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'

import './BusinessGuides.css';
import {Col, Row} from "reactstrap";
import GroupPng from "../../assets/Group.png";
import ReactHtmlParser from "react-html-parser";
import "react-modal-video/scss/modal-video.scss";
import './Useful.css';

export default function BusinessGuides(props) {
    const [businessGuides, setBusinessGuides] = useState([]);
    const [isButtonOpen, setIsButtonOpen] = useState(false);
    const [legalActs, setLegalActs] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const lang = localStorage.getItem("language");

    //Api Call
    useEffect(function () {
        localStorage.setItem("offset", 0);

        fetch(process.env.REACT_APP_API_URL + "/api/helpfull/guides/" + lang + "/" + 0)
            .then(res => res.json())
            .then(res => setBusinessGuides(res.guides))

        fetch(process.env.REACT_APP_API_URL + "/api/helpfull/researches/" + lang)
            .then(res => res.json())
            .then(res => {
                setResearches(res.guides)
            });

        fetch(process.env.REACT_APP_API_URL + "/api/helpfull/acts/" + lang)
            .then(res => res.json())
            .then(res => setLegalActs(res.acts))

        fetch(process.env.REACT_APP_API_URL + "/api/helpfull/webinars/" + lang)
            .then(res => res.json())
            .then(res => setSpeakers(res.webinars))

    }, [lang]);

    getUrlPath(props);


    const [researches, setResearches] = useState([]);

    const changeOffset = () => {
        const countOffset = parseInt(localStorage.getItem("offset")) + 4;

        localStorage.setItem("offset", countOffset);

        fetch(process.env.REACT_APP_API_URL + "/api/helpfull/guides/" + lang + "/" + localStorage.getItem("offset"))
            .then(res => res.json())
            .then(res => {
                if (res.guides.length && !res.next.length) {
                    setIsButtonOpen(true);
                    setBusinessGuides([...businessGuides, ...res.guides]);
                } else {
                    setBusinessGuides([...businessGuides, ...res.guides]);
                }
            })
    }

    return (
        <div className='container'>

            <div className="section">

                <h1 className="page-title">{lang === "am" ? "Բիզնես ուղեցույցներ" : "Business Guidelines"}</h1>

                <div className="section">
                    <div className='usful-section'>

                        {businessGuides.length ? businessGuides.map((item, key) => {
                            return (
                                <a key={key}
                                   href={lang === "am" ? process.env.REACT_APP_IMAGE_URL + "/helpfull_guides/" + item.pdf_am : process.env.REACT_APP_IMAGE_URL + "/helpfull_guides/" + item.pdf_en}
                                   target="_blank" rel="noopener noreferrer">
                                    <img className='bgImg'
                                         src={process.env.REACT_APP_IMAGE_URL + "/helpfull_guides/" + (lang === "am" ? item.pic_am : item.pic_en)}
                                         alt={"helpful guides"}/>
                                </a>
                            )
                        }) : null}

                    </div>

                    {!isButtonOpen ?

                        <div className="section-see-more">
                            <button className='btn'
                                    onClick={changeOffset}>{lang === "am" ? "Տեսնել ավելին" : "See More"}</button>
                        </div> : null}
                </div>


                <h2>{lang === "am" ? "Հետազոտություններ" : "Researches"}</h2>
                <div className='usful-section section'>

                    {researches ? researches.map((item, key) => {
                        return (
                            <React.Fragment key={key}>
                                <a href={lang === "am" ? process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + item.pdf_am : process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + item.pdf_en}
                                   target="_blank" rel="noopener noreferrer">
                                    <img className='bgImg'
                                         src={process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + (lang === "am" ? item.pic_am : item.pic_en)}/>
                                </a>
                            </React.Fragment>
                        )
                    }) : null}
                </div>


                {legalActs.length ? legalActs.map((item, key) => {
                    return (

                        <>
                            <h2>{lang === "am" ? "Իրավական ակտեր" : "Legal acts"}</h2>
                            <div className='legalActsContainer section'>
                                <Row className='legalActs' key={key}>
                                    <Col sm='1' className='legalActs1 col-12-none'>
                                        {item["file_" + lang] ?
                                            <a href={`${process.env.REACT_APP_IMAGE_URL}/helpfull_acts/${item["file_" + lang]}`}
                                               target="_blank" rel="noreferrer" className="legalActs__icon">

                                                <img className='legalActsPhoto' src={GroupPng}/>
                                            </a> : <img className='legalActsPhoto legalActs__icon' src={GroupPng}/>}
                                    </Col>

                                    <Col className='legalActs2'>
                                        <div className="legalActs2__container">

                                            {item["link_title_" + lang] ? <a href={item["link_" + lang]}
                                                                             target="_blank"
                                                                             rel="noreferrer">{item["link_title_" + lang]}</a>

                                                : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                }) : null}


                <h2>{lang === "am" ? "Վեբինարներ և տեսանյութեր" : "Webinars and videos"}</h2>
                <div className='webinarsSection section'>

                    {speakers.length ? speakers.map((speaker, key) => {
                        return (
                            <div className="vebinar_container" key={key}>
                                <div className="vebinar_item">
                                    <img className="vebinar_image"
                                         src={process.env.REACT_APP_IMAGE_URL + "/helpfull_webinars/" + (lang === "am" ? speaker.img_am : speaker.img_en)}/>

                                    <button className="vebinar_btn">
                                        <a href={speaker["embed_" + lang]} target="_blank" rel="noreferrer">
                                            <i className="fa fa-play" aria-hidden="true"></i>
                                        </a>
                                    </button>
                                </div>

                                <p className="vebinar_text">{ReactHtmlParser(speaker["text_" + lang])}</p>
                            </div>
                        );
                    }) : null}
                </div>
            </div>

        </div>
    )
}
