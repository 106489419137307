import React, {useState, useEffect} from 'react'


function LatestNews() {
    const [data, setData] = useState([]);
    const lang = localStorage.getItem("language");

    const [allData, setAllData] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/api/blog/all/home/" + lang)
            .then(res => res.json())
            .then(res => {
                setData(res.blog);

                var rev = res.blog.reverse();

                setAllData(rev);
            })
            .catch(err => console.error(err))
    }, [lang]);

    return (
        <div className='latestNewsContainer container'>
            <h3 className='heading'>{lang === "am" ? "վերջին նորություններ" : "Latest News"}</h3>

            <div className="card-group">

                {data.length ? allData.map((news, key) => {
                    return (
                        key !== 0 ? <React.Fragment key={key}>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 card-container">
                                <a className="card item"
                                   href={`${lang}/${lang === "am" ? 'նորություններ' : 'news'}/${news["url_" + lang]}`}>
                                    <img className="card-img-top" src={`${process.env.REACT_APP_IMAGE_URL}/blog/${news.pic}`}  alt={"news"}/>
                                    <div className="card-body">
                                        <div>
                                            <p className="card-text">{news.date}</p>

                                        </div>
                                        <h5 className="card-title title">
                                            {lang === "am" ? news.title_am : news.title_en}
                                        </h5>
                                    </div>
                                </a>
                            </div>

                        </React.Fragment> : null
                    )
                }) : null}
            </div>

            <div className={"section-see-more"}>
                <a href={`${process.env.REACT_APP_URL}${lang}/${lang === "en" ? "sections" : "հատվածներ"}/${lang === "en" ? "news-date" : "նորությունների-ամսաթիվ"}`}
                   className={"btn"}>
                    {lang === "am" ? "Բոլոր նորությունները" : "All News"}
                </a>
            </div>
        </div>
    )
}

export default LatestNews
