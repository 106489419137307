import React, {useState, useEffect} from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'
import ReactHtmlParser from 'react-html-parser';

export default function BusinessPlan (props) {
  const [apiData, setApiData] = useState([]);
  const lang = localStorage.getItem("language");

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/programs/plans/" + lang)
    .then(res => res.json())
    .then(res => setApiData(res.plans))
  }, []);

  getUrlPath(props)


  const targetedArea = [
    {header: "SME DEVELOPMENT", data: [
      "Assistance to the SME Development Council",
      "Assist the Government in the implementation of the SME strategy 2020-2024",
      "Identify and analyze the obstacles within the liquidation process of businesses and provide recommendations",
      "Follow-up on the recommendations provided within the SME integrity survey report",
      "Improve policy measures to diversify access to finance, such as crowd-funding, angel investors, and venture capital",
      "Assist in the implementation of the points of the Social Entrepreneurship concept paper",
      "Assist in the implementation of the law on “State support to IT sector”.",
      "Assist the Government in improving further Armenia’s ranking in the Doing Business",
      "Support the implementation of Global Entrepreneurship Monitor report 2021",
      "Review the Labour Code related issues that make obstacles for SMEs"
    ]},
    {header: "RESPONDING TO THE ECONOMIC IMPACTS OF THE CORONAVIRUS PANDEMIC", data: [
     "Design and develop a virtual system for getting permits and licenses, including improvement in procedures",
     "Promote prepared guidelines aimed at helping the establishment of production and diversification of imports of specific products",
     "Implementation of recommendations on agriculture production provided within the optimal organisation of the post-harvest chain by Armenian agricultural producers",
     "Continue the trainings and coordination of the funding of regional start-ups within Government’s 19th measure"
    ]},
    {header: "PR AND COMMUNICATION", data: [
      "Maximise outreach and impact of the business guidelines on detailed business creation and operation regulations and step-by-step procedures",
      "Continue awareness raising activities, meetings with SMEs in the regions of Armenia and Yerevan, meetings with NGOs and business associations, networking and e-communication"
    ]}
  ];

  return (
    <>

        <div className='businessPlanContainer businessPlanContainer--first'>
          {ReactHtmlParser(apiData["text_" + lang])}
        </div>

        <div className="businessPlanContainer">
          <h5 className="targeted_area_title">Targeted Reform Areas</h5>

          {targetedArea.map((header, headerKey) => (
            <React.Fragment key={headerKey}>
              <div className="targeted_area_header">{header.header}</div>

              {header.data.map((data, dataKey) => {
               return (
                <div key={dataKey} className={(dataKey % 2 === 0) ? "targeted_area_section" : "targeted_area_section targeted_area_section--light"}>
                  <p>{data}</p>
                </div>
               )
              })}
            </React.Fragment>
          ))}
        </div>

        <a href={`${process.env.REACT_APP_IMAGE_URL}/programs_plans/${lang === "am" ? apiData.file_am : apiData.file_en}`} rel="noreferrer" target="_blank" className="download-annual-btn">
          <button className='allNewsBtn' style={{marginBottom: "50px"}}>
            {lang === "am" ? "Ներբեռնել" : "Download"}
          </button>
        </a>

    </>
  )
}
