import React, {useState, useEffect} from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'

export default function Researches(props) {
  const [researches, setResearches] = useState([]);
  const lang = localStorage.getItem("language");

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/helpfull/researches/" + lang)
    .then(res => res.json())
    .then(res => {
      setResearches(res.guides)
    })
  }, [lang]);

  getUrlPath(props);

  return (
    <div className='app'>

      <div className='usful-section'>
        {researches ? researches.map((item, key) => {
          return (
            <React.Fragment key={key}>
              <a href={lang === "am" ? process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + item.pdf_am : process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + item.pdf_en} target="_blank" rel="noopener noreferrer">
                <img className='bgImg' src={process.env.REACT_APP_IMAGE_URL + "/helpfull_researches/" + (lang === "am" ? item.pic_am : item.pic_en)} />
              </a>
            </React.Fragment>
          )
        }) : null}
      </div>
    </div>
  )
}
