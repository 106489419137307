import React from 'react';
import { Row } from 'reactstrap';
import Group4 from '../../assets/Group4.png'
import Group3 from '../../assets/Group3.png'
import Group2 from '../../assets/Group2.png'
import Group1 from '../../assets/Group1.png'

export default function BSO () {
  return (
    <div className='secondBSObanner'>
      <div className='icons container'>
        <Row id='banner'>
          <div className="col-sm-3 col-6">
            <img src={Group1} alt="" />
          </div>
          
          <div className="col-sm-3 col-6">
            <img src={Group4} alt="" />
          </div>
          
          <div className="col-sm-3 col-6">
            <img src={Group2} alt="" />
          </div>

          <div className="col-sm-3 col-6" >

            <img src={Group3} alt="" />
          </div>
        </Row>
      </div>
    </div>
  )
}
