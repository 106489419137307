export const getUrlPath = (props) => {
  const pathname = props.location.pathname.split('/');
  let path_id = pathname[2];
  let path_controller = pathname[3];

  if (path_controller && path_controller.includes("-")) {
    path_controller = path_controller.split("-").join(" ");
  }

  if (path_id.includes("-")) {
    path_id = path_id.split("-").join(" ");
  }

  return {
    path_id:path_id,
    path_controller:path_controller
  }
}

