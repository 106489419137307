import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';

const Slider = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const lang = localStorage.getItem("language");

  const next = () => {
    if (animating) return;
    
    const nextIndex = activeIndex === props.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = props.images.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.pic}
      >

        <div className="row">
          <div className=" left-side col-lg-6 col-md-6 col-sm-12 col-12">
            <div className='textOnSlider'>
              <h2 className='rootText'>{item["title_" + lang]}</h2>

              <p className='rootParagraph'>{ReactHtmlParser(item["text_" + lang])}</p>

              <a href={lang + (lang === "am" ? "/նորություններ/" : "/news/") + item["link_" + lang]} style={{fontSize: "16px", color: "white", textDecoration: "none"}}>
                <button className='rootBtn'>{lang ===  "am" ? "Տեսնել ավելին" : "See More"}</button>
              </a>
            </div>
          </div>

          <div className=" right-side col-lg-6 col-md-6 col-sm-none ">
            <img
                style={{width: '100%', height:'570px', objectFit:'cover'}}
                src={process.env.REACT_APP_IMAGE_URL + "/home_slider/" + item.pic}
            />
          </div>

        </div>

      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={props.images} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
    </Carousel>
  );
};

export default Slider;