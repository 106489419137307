import React from 'react'

export default function ScrollingFooter() {

    const lang = localStorage.getItem("language");
  return (
    <div className='scrollingFooter'>
      <p style={{textAlign:'center'}}>{lang === "am" ? "Հաջողակ բարեփոխումների իրականացում 2007 թվականից" : "Implementing successful reforms since 2007"}  </p>
    </div>
  )
}
