import React from "react";
import Slider from "react-styled-carousel";
import member1 from "../assets/member1.png";
import member2 from "../assets/member2.png";
import member3 from "../assets/member3.png";
import member4 from "../assets/member4.png";
import member5 from "../assets/member5.png";
import member6 from "../assets/member6.png";
import member7 from "../assets/member7.png";
import member8 from "../assets/member8.png";
import member9 from "../assets/member9.png";
import member10 from "../assets/member10.png";
import member11 from "../assets/member11.png";

const Cards = () => {

    const responsive = [
        {breakPoint: 576, cardsToShow: 2},
        {breakPoint: 768, cardsToShow: 3},
        {breakPoint: 992, cardsToShow: 4},
        {breakPoint: 1280, cardsToShow: 4}
                                        ];
    return (
        <React.Fragment>
            <div className={"member-slide"}>

                <div className={"container"}>


                    <Slider showDots={false} cardsToShow ={2} responsive={responsive}>

                        <a href={"https://amcham.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member1} alt=""/>
                        </a>

                        <a href={"https://www.acses.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member2} alt=""/>
                        </a>

                        <a href={"https://www.developers.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member3} alt=""/>
                        </a>

                        <a href={"https://anau.am/en/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member4} alt=""/>
                        </a>

                        <a href={"https://www.eif.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member5} alt=""/>
                        </a>

                        <a href={"https://engineeringassociation.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member6} alt=""/>
                        </a>

                        <a href={"https://www.worldfzo.org/#"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member7} alt=""/>
                        </a>
                        <a href={"https://www.uate.org/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member8} alt=""/>
                        </a>

                        <a href={"https://sme.am/en/eng/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member9} alt=""/>
                        </a>

                        <a href={"https://finarm.am/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member10} alt=""/>
                        </a>

                        <a href={"https://polytech.am/en/polytechnic/"} rel={'noreferrer'} target={'_blank'} className="slide-item">
                            <img src={member11} alt=""/>
                        </a>

                    </Slider>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Cards;