import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './News.css';
import PDFimage from '../../assets/PDF.png'
import ModalImage from "react-modal-image"
import { useLanguage } from '../LanguageProvider/LanguageProvider';
import { Helmet } from "react-helmet-async";

const removeHTML = (str) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
}

const SectionFirst = () => {
  let newsUrl = window.location.pathname;
  newsUrl = newsUrl.split("/");
  newsUrl = newsUrl[newsUrl.length - 1];
  
  const [data, setData] = useState(null);
  const [mediaFiles, setMediaFiles] = useState(null);
  
  const lang = localStorage.getItem("language");
  const {setNewsAmUrl, setNewsEnUrl} = useLanguage();
  
  //Api Call
  useEffect(function () {
    fetch(`${process.env.REACT_APP_API_URL}/api/blog/one/${decodeURIComponent(newsUrl)}/${lang}`)
      .then(res => res.json())
      .then(res => {
        setNewsAmUrl(res.blog.url_am);
        setNewsEnUrl(res.blog.url_en);
        
        localStorage.setItem("newsEnUrl", res.blog.url_en);
        localStorage.setItem("newsAmUrl", res.blog.url_am);
        
        setData(res.blog);
        setMediaFiles(res.med);
      })
  }, []);
  
  return (
    <>
      {data && <>
        <Helmet prioritizeSeoTags>
          <title>{data[`title_${lang}`]}</title>
          <meta name="title" key="title" content={data[`title_${lang}`]}/>
          
          <meta name="description" content={removeHTML(data[`text_${lang}`]).slice(0, 160)}/>
          
          <meta name="title" key="title" content={data[`title_${lang}`]}/>
          <meta property="og:title" key="og:title" content={data[`title_${lang}`]}/>
          <meta property="og:type" key="og:type" content="website"/>
          <meta property="og:description" content={removeHTML(data[`text_${lang}`]).slice(0, 160)} />
          <meta property="og:url" content={decodeURIComponent(window.location.href)}/>
          <meta property="og:image" key="og:image" content={`${process.env.REACT_APP_IMAGE_URL}/blog/` + data.pic} />
          
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={decodeURIComponent(window.location.href)} />
          <meta property="twitter:title" content={data[`title_${lang}`]} />
          <meta property="twitter:description" content={removeHTML(data[`text_${lang}`]).slice(0, 160)}/>
          <meta property="twitter:image" content={`${process.env.REACT_APP_IMAGE_URL}/blog/` + data.pic} />
          
        </Helmet>
        
        <div className="news_container single_news container">
          <div className="row">
            
            <h1 className="page-title">{data[`title_${lang}`]}</h1>
            
            <div className="card-group">
              
              <div className=" col-12 card-container">
                
                <div>
                  <p className="news-date">{data.date}</p>
                </div>
                
                <div className="card item">
                  <div className={'col-lg-12 col-md-12 col-sm-12 col-12 text-center'}>
                    <img className="card-img-top"
                         src={process.env.REACT_APP_IMAGE_URL + '/blog/' + data.pic}
                         alt={'news'}/>
                  </div>
                  
                  <div className="card-body card-body col-lg-12 col-md-12 col-sm-12 col-12">
                    
                    <div className="card-text news_paragraph">
                      {ReactHtmlParser(data[`text_${lang}`])}
                    </div>
                    
                    <div className="mt-5 mediaFlex">
                      {mediaFiles && mediaFiles.map((item, key) => (
                        <div key={key} className="mediaFlexItem">
                          {item.type === 'image'
                            ? <ModalImage className="mediaFlexImage" hideDownload={true} hideZoom={true} small={process.env.REACT_APP_IMAGE_URL + '/blog_media/' + item.file} large={process.env.REACT_APP_IMAGE_URL + '/blog_media/' + item.file} />
                            : item.type === 'video'
                              ? <video controls src={process.env.REACT_APP_IMAGE_URL + '/blog_media/' + item.file}/>
                              : <a href={process.env.REACT_APP_IMAGE_URL + '/blog_media/' + item.file}><img className="mediaFlexPdf" src={PDFimage} alt={'download PDF'}/></a>}
                        </div>
                      ))}
                    </div>
                  
                  </div>
                </div>
              
              </div>
            </div>
          
          </div>
        
        </div>
      
      </>}
    </>
  )
}

export default SectionFirst;
