import React, {useEffect, useState} from 'react';
import {getUrlPath} from "../../helpers/getUrlPath";
import ReactHtmlParser from "react-html-parser";
import {CardBody} from "reactstrap";

const GenderEquality = (props) => {
    const [projects, setProjects] = useState([]);
    const [isOpen, setIsOpen] = useState(-1);

    const lang = localStorage.getItem("language");

    getUrlPath(props);

    const newToggle = key => key === isOpen ? setIsOpen(-1) : setIsOpen(key);

    //Api Call
    useEffect(function () {
        fetch(process.env.REACT_APP_API_URL + "/api/gender/GenderMainstreaming/" + lang)
            .then(res => res.json())
            .then(res => setProjects([res]))
    }, [lang]);

    return (
        <div>

            <div className='gender_box' style={{overflow: 'hidden'}}>
                {projects.length ? projects.map((item, key) => {
                    return (
                        <div className="gender_container" key={key}>
                            <div className="going_project_img_container">
                                <div className='test_text'>
                                    {ReactHtmlParser(item.genderstext[0]["text_" + lang])}
                                </div>
                            </div>
                            <div className="link_or_pdf">
                                {item.files.length ? item.files.map((file, key) => {
                                    return (
                                        <div className='toast' style={{marginTop: "35px"}} key={key}>
                                            {file.type === 'link' ?
                                                <a className='gender_Pdf_link' rel="noreferrer" target="_blank"
                                                   href={file["files_" + lang]}>
                                                    <div className='header'>
                                                        <p>{file["files_name_" + lang]}</p>
                                                        <svg className='link_icon' xmlns="http://www.w3.org/2000/svg"
                                                             width="20.861" height="20.877" viewBox="0 0 20.861 20.877">
                                                            <g id="Group_360" data-name="Group 360"
                                                               transform="translate(-366.668 -123.121)">
                                                                <path id="Path_809" data-name="Path 809"
                                                                      d="M380.86,326.487l-1.929,1.919c-.022-.021-.058-.053-.093-.088q-.872-.871-1.743-1.743a1.257,1.257,0,0,0-1.84,0q-2.746,2.746-5.49,5.5a1.26,1.26,0,0,0,0,1.851l1.761,1.764a1.266,1.266,0,0,0,1.9-.006q.91-.911,1.82-1.823a1.088,1.088,0,0,0,.1-.133,5.264,5.264,0,0,0,3.115.721c-.038.043-.062.074-.089.1-1.04,1.042-2.077,2.088-3.123,3.124a3.942,3.942,0,0,1-5.521.023q-.992-.966-1.957-1.959a3.956,3.956,0,0,1,.049-5.535q2.763-2.769,5.529-5.534a3.959,3.959,0,0,1,5.654.008q.856.857,1.714,1.713A1.126,1.126,0,0,0,380.86,326.487Z"
                                                                      transform="translate(0 -194.808)" fill="#243746"/>
                                                                <path id="Path_810" data-name="Path 810"
                                                                      d="M607.092,135.429l1.927-1.921c.032.03.068.063.1.1l1.722,1.724a1.26,1.26,0,0,0,1.88.005l5.471-5.476a1.265,1.265,0,0,0,0-1.853q-.885-.887-1.771-1.773a1.262,1.262,0,0,0-1.881,0l-1.85,1.851c-.032.032-.066.063-.1.1a5.211,5.211,0,0,0-3.1-.705c.049-.052.081-.088.115-.121q1.531-1.533,3.063-3.066a3.958,3.958,0,0,1,5.625-.006q.918.913,1.83,1.832a3.957,3.957,0,0,1,0,5.625q-2.742,2.751-5.49,5.5a3.954,3.954,0,0,1-5.693-.008q-.851-.852-1.7-1.7A1.139,1.139,0,0,0,607.092,135.429Z"
                                                                      transform="translate(-233.766 0)" fill="#243746"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </a>
                                                :
                                                <a className='gender_Pdf_link' rel="noreferrer" target="_blank"
                                                   href={process.env.REACT_APP_IMAGE_URL + `/gender_files/` + (lang === "am" ? file.files_am : file.files_en)}>
                                                    <div className='header'>
                                                        <p>{file["files_name_" + lang]}</p>
                                                        <p className='gender_Pdf_link_logo'>
                                                            PDF
                                                        </p>
                                                    </div>
                                                </a>
                                            }
                                        </div>
                                    )
                                }) : null}
                            </div>

                            {item.genders.length ? item.genders.map((links, key) => {
                                return (
                                    <div className='toast' style={{marginTop: "35px"}} key={key}>
                                        <div className={`header ${isOpen === key ? "header_open" : ""}`}
                                             onClick={() => newToggle(key)}>
                                            <p>{links["link_title_" + lang]}</p>
                                            {isOpen !== key ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.534" height="10.545"
                                                     viewBox="0 0 16.534 10.545">
                                                    <g id="Group_364" data-name="Group 364"
                                                       transform="translate(-565.152 -689.549)">
                                                        <path id="Path_808" data-name="Path 808"
                                                              d="M573.267,700.458l-7.712-7.854a1.4,1.4,0,0,1,2-1.966l5.71,5.814,6.014-6.12a1.4,1.4,0,0,1,2,1.968Z"
                                                              transform="translate(0 -0.364)" fill="#243746"/>
                                                    </g>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.536" height="10.545"
                                                     viewBox="0 0 16.536 10.545">
                                                    <g id="Group_359" data-name="Group 359"
                                                       transform="translate(581.687 700.459) rotate(180)">
                                                        <path id="Path_808" data-name="Path 808"
                                                              d="M573.267,700.459l-7.713-7.854a1.4,1.4,0,0,1,2-1.967l5.711,5.815,6.015-6.12a1.4,1.4,0,0,1,2,1.968Z"
                                                              transform="translate(0 0)" fill="#243746"/>
                                                    </g>
                                                </svg>
                                            }
                                        </div>
                                        <CardBody style={{display: isOpen === key ? "block" : "none"}} className='body'>
                                            {links.link.length ? links.link.map((link, key) => {
                                                return (
                                                    <React.Fragment key={key}>
                                                        <a href={link["link_" + lang]} rel="noreferrer" target="_blank">{link["link_name_" + lang]}</a>
                                                    </React.Fragment>
                                                );
                                            }) : null}
                                        </CardBody>
                                    </div>
                                )
                            }) : null}

                        </div>
                    )
                }) : null}

            </div>
        </div>
    );
};

export default GenderEquality;
