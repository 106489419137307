import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Layout/Navbar/Header';
import Home from './components/Home';

//components
import { AboutUs, Office, Reports } from './components/OurStaff/index';
import { BusinessGuides, LegalActs, Researches, WebinarsAndVideos } from './components/UsefulTopics/index';
import { Contact, MeetingsAndProtocols, MissionAndStructure } from './components/SMEtip/index';
import { BusinessPlan, GoingProjects, ImplementedProgramsAndReforms } from './components/ProjectsAndReforms/index';

import SectionFirst from './components/News/SectionFirst';
import SectionSecond from './components/News/SectionSecond';
import NotFound from './components/Layout/NotFound/NotFound';
import Footer from './components/Layout/Footer/Footer';
import ScrollingFooter from './components/Layout/ScrollingFooter'
import GenderMainstreaming from "./components/Gender/GenderMainstreaming";
import GenderEquality from "./components/Gender/GenderEquality";
import './App.css';

import { LanguageProvider } from './components/LanguageProvider/LanguageProvider';
import { Helmet, HelmetProvider } from "react-helmet-async";

export default class App extends Component {
  
  render() {
    const lang = localStorage.getItem("language");
    const legalact1 = process.env.REACT_APP_URL + "wp-content/uploads/2013/03/ՀՀ-օրենքը-«Տեղեկատվական-տեխնոլոգիաների-ոլորտի-պետական-աջակցության-մասին».pdf";
    const legalact2 = process.env.REACT_APP_URL + "wp-content/uploads/2017/01/Ընտանեկան-ձեռնարկատիրություններին-հարկային-արտոնություններ-տրամադրելու-մասին-ՀՀ-օրենք.docx";
    
    const guideline1 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Ջերմոցային-տնտեսության-ուղեցույց.pdf";
    const guideline2 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Աննդի-արտադրության-ուղեցույց.pdf";
    const guideline3 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Հագուստի-արտադրության-ուղեցույց.pdf";
    const guideline4 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Լվացող-միջոցների-արտադրության-ուղեցույց.pdf";
    const guideline5 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Պլաստիկե-տարաների-արտադրություն.pdf";
    const guideline6 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/ՀՀ-մշակող-արդյունաբերության-հումքի-և-նյութերի-ներմուծման-այլընտրանքային-աղբյուրների-ուղեցույց.pdf";
    const guideline7 = process.env.REACT_APP_URL + "wp-content/uploads/2021/04/Հայկական-ապրանքների-արտահանումը-դեպի-Միջին-Արևեկքի-Ծոցի-և-Միջերկրածովյան-երկրներ.pdf";
    
    const helmetContext = {};
    
    return (
      
      <HelmetProvider>
        
        <Helmet prioritizeSeoTags>
          <title>Investment Council of Armenia - IC Armenia</title>
          <meta name="description"
                content={'IC Armenia serves as a public-private dialogue platform between the Government of Armenia and business associations targeted at the at business-oriented legislative reforms.'}/>
          
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content='https://icarmenia.am' />
          <meta property="twitter:title" content='Investment Council of Armenia - IC Armenia' />
          <meta property="twitter:image" content='https://www.icarmenia.am/favicon/metaimg.jpeg' />
          <meta name="twitter:description"content={'IC Armenia serves as a public-private dialogue platform between the Government of Armenia and business associations targeted at the at business-oriented legislative reforms.'}/>
          
          <meta name="title" key="title" content='Investment Council of Armenia - IC Armenia'/>
          <meta property="og:title" key="og:title" content='Investment Council of Armenia - IC Armenia'/>
          <meta property="og:type" key="og:type" content="website"/>
          <meta property="og:url" content="https://www.icarmenia.am"/>
          <meta property="og:description"
                content={'IC Armenia serves as a public-private dialogue platform between the Government of Armenia and business associations targeted at the at business-oriented legislative reforms.'}/>
          <meta property="og:image" content='https://www.icarmenia.am/favicon/metaimg.jpeg'/>
        </Helmet>
        
        <Router>
          
          <LanguageProvider>
            <Header/>
            
            <Switch>
              
              {/* Legal Acts Old Links */}
              {decodeURIComponent(window.location.href) === legalact1 ? window.location.href = process.env.REACT_APP_URL + "legalacts/ՀՀ-օրենքը-«Տեղեկատվական-տեխնոլոգիաների-ոլորտի-պետական-աջակցության-մասին».pdf" : null}
              
              {decodeURIComponent(window.location.href) === legalact2 ? window.location.href = process.env.REACT_APP_URL + "legalacts/Ընտանեկան-ձեռնարկատիրություններին-հարկային-արտոնություններ-տրամադրելու-մասին-ՀՀ-օրենք.docx" : null}
              
              {/* Guideline Old Links */}
              {decodeURIComponent(window.location.href) === guideline1 ? window.location.href = process.env.REACT_APP_URL + "guideline/Ջերմոցային-տնտեսության-ուղեցույց.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline2 ? window.location.href = process.env.REACT_APP_URL + "guideline/Աննդի-արտադրության-ուղեցույց.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline3 ? window.location.href = process.env.REACT_APP_URL + "guideline/Հագուստի-արտադրության-ուղեցույց.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline4 ? window.location.href = process.env.REACT_APP_URL + "guideline/Լվացող-միջոցների-արտադրության-ուղեցույց.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline5 ? window.location.href = process.env.REACT_APP_URL + "guideline/Պլաստիկե-տարաների-արտադրություն.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline6 ? window.location.href = process.env.REACT_APP_URL + "guideline/ՀՀ-մշակող-արդյունաբերության-հումքի-և-նյութերի-ներմուծման-այլընտրանքային-աղբյուրների-ուղեցույց.pdf" : null}
              {decodeURIComponent(window.location.href) === guideline7 ? window.location.href = process.env.REACT_APP_URL + "guideline/Հայկական-ապրանքների-արտահանումը-դեպի-Միջին-Արևեկքի-Ծոցի-և-Միջերկրածովյան-երկրներ.pdf" : null}
              
              {/* Home */}
              <Route exact path='/' component={Home}/>
              <Route exact path='/:lang' component={Home}/>
              
              {/* SME TIP */}
              <Route exact
                     path={`/:lang/${lang === "am" ? "ՓՄՁ-Խորհուրդ/Առաքելություն-կառուցվածք" : "SME-Council/Mission-Structure"}`}
                     component={MissionAndStructure}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "ՓՄՁ-Խորհուրդ/Նիստեր-արձանագրություններ" : "SME-Council/Meetings-minutes"}`}
                     component={MeetingsAndProtocols}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "ՓՄՁ-Խորհուրդ/Դիմեք-մեզ" : "SME-Council/Report-business-issue"}`}
                     component={Contact}/>
              
              {/* Reforms */}
              <Route exact
                     path={`/:lang/${lang === "am" ? "Նախագծեր-բարեփոխումներ/Ընթացիկ-նախագծեր" : "Projects-reforms/Current-projects"}`}
                     component={GoingProjects}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Նախագծեր-բարեփոխումներ/Իրականացված-ծրագրեր" : "Projects-reforms/Delivered-projects-reforms"}`}
                     component={ImplementedProgramsAndReforms}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Նախագծեր-բարեփոխումներ/Գործողությունների-ծրագիրը" : "Projects-reforms/Action-plan"}`}
                     component={BusinessPlan}/>
              
              {/* useful */}
              <Route exact
                     path={`/:lang/${lang === "am" ? "Օգտակար-նյութեր/Բիզնես-ուղեցույցներ" : "Useful-materials/Business-Guidelines"}`}
                     component={BusinessGuides}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Օգտակար-նյութեր/Հետազոտություններ" : "Useful-materials/Researches"}`}
                     component={Researches}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Օգտակար-նյութեր/Իրավական-ակտեր" : "Useful-materials/Legal-acts"}`}
                     component={LegalActs}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Օգտակար-նյութեր/Վեբինարներ-տեսանյութեր" : "Useful-materials/Webinars-videos"}`}
                     component={WebinarsAndVideos}/>
              
              {/* gender */}
              <Route exact
                     path={`/:lang/${lang === "am" ? "Գենդերային-անկյուն/Գենդերային-հավասարությունը" : "Gender-Corner/Gender-equality"}`}
                     component={GenderEquality}/>
              <Route exact
                     path={`/:lang/${lang === "am" ? "Գենդերային-անկյուն/Գենդերային-ուղղորդում" : "Gender-Corner/Gender-mainstreaming"}`}
                     component={GenderMainstreaming}/>
              
              {/* about */}
              <Route exact path={`/:lang/${lang === "am" ? "Մենք/անձնակազմը" : "We/our-team"}`}
                     component={AboutUs}/>
              <Route exact path={`/:lang/${lang === "am" ? "Մենք/գրասենյակը" : "We/office"}`}
                     component={Office}/>
              <Route exact path={`/:lang/${lang === "am" ? "Մենք/Հաշվետվություններ" : "We/Reports"}`}
                     component={Reports}/>
              
              {/* News */}
              <Route exact path={`/:lang/${lang === "am" ? "նորություններ" : "news"}/:news`}
                     component={() => <SectionFirst/>}/>
              <Route exact path={`/:lang/${lang === "am" ? "հատվածներ" : "sections"}/:news`}
                     component={SectionSecond}/>
              
              {/* Not Found */}
              <Route component={NotFound}/>
            </Switch>
          
          </LanguageProvider>
          
          
          <ScrollingFooter/>
          
          <Footer/>
        </Router>
      
      </HelmetProvider>
    )
  }
}
