import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap';
import { getUrlPath } from '../../helpers/getUrlPath'
import GroupPng from '../../assets/Group.png'
import './UsefulTopics.css'

import ReactHtmlParser from 'react-html-parser';

export default function LegalActs (props) {
  const [legalActs, setLegalActs] = useState([]);
  const lang = localStorage.getItem("language");

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/helpfull/acts/" + lang)
    .then(res => res.json())
    .then(res => setLegalActs(res.acts))
  }, [lang]);

  getUrlPath(props);

  return (
    <>

      <div className='legalActsContainer'>
        {legalActs.length ? legalActs.map((item, key) => {
          return (
            <Row className='legalActs' key={key}>
              <Col sm='1' className='legalActs1'>
                {item["file_" + lang] ?  <a href={`${process.env.REACT_APP_IMAGE_URL}/helpfull_acts/${item["file_" + lang]}`} rel="noreferrer" target="_blank" className="legalActs__icon">
                  <img className='legalActsPhoto' src={GroupPng} />
                </a> : <img className='legalActsPhoto legalActs__icon' src={GroupPng} />}
              </Col>

              <Col className='legalActs2'>
                <div className="legalActs2__container">
                  <p className="legalActs2__text">{ReactHtmlParser(item["text_" + lang])}</p>
                  
                  <br />

                  {item["link_title_" + lang] ? <a href={item["link_" + lang]} style={{fontSize: "14px"}}>{item["link_title_" + lang]}</a> : null}
                </div>
              </Col>
            </Row>
          )
        }) : null}
        </div>
    </>
  )
}
