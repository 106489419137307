import React, {useRef} from 'react';
import {  Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = () => toast.info("հաղորդագրությունը հաջողությամբ ուղարկվեց");

export default function LeaveASuggestion() {
  const nameSurnameRef = useRef(null);
  const emailRef = useRef(null);
  const textRef = useRef(null);
  const lang = localStorage.getItem("language");

  function sendEmail(e) {
    e.preventDefault();

    fetch(process.env.REACT_APP_API_URL + "/api/home/questions", {
      method: "POST",
      headers: {
        'Content-Type':'application/json',
      },
      body: JSON.stringify({name: nameSurnameRef.current.value, email: emailRef.current.value, question: textRef.current.value})
    })
    .then(res => res.json())
    .then(res => {
      if (res.success) notify();
    });

    e.target.reset();
  }
  return (
      <>
      <form onSubmit={sendEmail}>
         <div className='suggestionEmail' >
          <div>
          <textarea ref={textRef} id="" cols="30" rows="10" name="message" required/>
          </div>
          <Row style={{padding: "1.25rem"}}>
            <Col className='suggestCol' lg={6} md={6} xs={12}>
              <input ref={nameSurnameRef} type="text" style={{width:'100%'}} placeholder={lang === "am" ? "Անուն,ազգանուն" : "Narek,Sargsyan"} name='name' required/>
            </Col>

            <Col className='suggestCol' lg={6} md={6} xs={12}>
              <input ref={emailRef} style={{width:'100%'}} type="email" placeholder={lang === "am" ? "էլ. փոստ" : "Email"} name="email" required/>
            </Col>

            <Col>
              <input type="submit" className="sendEmailBtn" value={lang === "am" ? "Ուղարկել" : "Submit"}/>
            </Col>
         </Row>
         </div>
       </form>

       <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

 