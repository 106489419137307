import React from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'
import './SME.css';
import ContactUs from '../Layout/ContactUs'

export default function Contact(props) {
  const lang = localStorage.getItem("language");

  getUrlPath(props)
  
  const {path_id, path_controller} = getUrlPath(props)

  return (
    <>
        <div className='urlHeading'> 
          <p>{lang === "am" ? "հիմնական" : "main"}</p> <i className="fa fa-arrow-right" aria-hidden="true"></i>  {path_id} <i className="fa fa-arrow-right" aria-hidden="true"></i>  <span style={{color:'#a2babc'}}>{path_controller}</span> 
        </div> 
        
        <div className='reportContainer'>
          <ContactUs />
        </div>
    </>
  )
}
