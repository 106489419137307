import React, { createContext, useContext ,useState } from "react";

const LanguageContext = createContext({});

const LanguageProvider = ({children}) => {
    const [newsAmUrl, setNewsAmUrl] = useState("")
    const [newsEnUrl, setNewsEnUrl] = useState("")

    return <LanguageContext.Provider value={{
        newsAmUrl, setNewsAmUrl, newsEnUrl, setNewsEnUrl
    }}> 
        {children}
    </LanguageContext.Provider>
}

const useLanguage = () => {
    return useContext(LanguageContext)
}

export { useLanguage, LanguageProvider }

