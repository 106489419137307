import React from 'react'
import './Footer.css'
import "react-toastify/dist/ReactToastify.css";
import BSO2 from "../BSO2";
import {useLocation} from 'react-router-dom'
import NewsletterSubscribe from "./NewsletterSubscribe";


export default function Footer() {
    const lang = localStorage.getItem("language");

    const location = useLocation();

    return (

        <div>
            <div className='footer'>

                <div className="container">
                    <div className="row">
                        <div className="contact-us col-lg-6 col-md-6 col-sm-12 col-12">

                            <h3>{lang === "am" ? "ՀԵՏԱԴԱՐՁ ԿԱՊ" : "CONTACT US"}</h3>

                            <a className="contact-links"
                               href="https://goo.gl/maps/Xj4tEd4EF9CeBsJo7" target="_blank" rel="noreferrer">
                                <i className="fas fa-home"/>
                                {lang === "am" ? "ՀՀ Երևան 0010 Վ.Սարգսյան 10 " : "10 V. Sargsyan, 0010 Yerevan"}
                            </a>

                            <a className="contact-links text-inherit" href="mailto: info@icarmenia.am">
                                <i className="fa fa-envelope"/>
                                info@icarmenia.am
                            </a>
                            <a className="contact-links" href="tel: +37411703370">
                                <i className="fa fa-phone-alt"/>
                                +37411703370
                            </a>

                        </div>
                        <div className="subscribe-us col-lg-6 col-md-6 col-sm-12 col-12 text-end">
                            <div className={"text-start d-inline-block"}>
                                <h3>{lang === "am" ? "ԲԱԺԱՆՈՐԴԱԳՐՎԵԼ" : "SUBSCRIBE TO IC ARMENIA NEWS"}</h3>

                                <NewsletterSubscribe/>

                                    <div>
                                        <a className="social-links" target="_blank" rel="noreferrer"
                                           href="https://www.facebook.com/InvestmentCouncilArmenia">
                                            <i className="fab fa-facebook-f"/>
                                        </a>
                                        <a className="social-links" target="_blank" rel="noreferrer"
                                           href="https://www.linkedin.com/company/icarmenia/">
                                            <i className="fab fa-linkedin-in"/>
                                        </a>
                                        <a className="social-links" target="_blank" rel="noreferrer"
                                           href="//www.youtube.com/channel/UCpD0Kap4MBdUY2MW84Uel6A/videos">
                                            <i className="fab fa-youtube"/>
                                        </a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {(location.pathname === "/en") || (location.pathname === "/am") ? <BSO2/> : <div/>}

        </div>
    )
}
