import React, {useState, useEffect} from 'react';
import Slider from './Layout/Slider';
import LatestNews from './Layout/LatestNews';
import {withRouter} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import MemberSlide from "./MemberSlide";

function Home() {

    let lang = localStorage.getItem("language");

    const [sliderData, setSliderData] = useState([]);

    const [popupOpen, setPopupOpen] = useState(false);

    const [againSubscribed, setAgainSubscribed] = useState(false);


    //Api Call
    useEffect(function () {
        if (window.location.href.includes("?subscribe=")) {
            let email = window.location.href.split("?subscribe=")[window.location.href.split("?subscribe=").length - 1];

            fetch(process.env.REACT_APP_API_URL + "/api/home/check_subscribe", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: email})
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status && res.status === "Already unsubscribe") {
                        window.location.href = process.env.REACT_APP_URL;
                    } else if (res.status && res.status === "unsubscribe") {
                        setPopupOpen(true);
                    }
                })
                .catch(err => console.log(err))
        }

        fetch(process.env.REACT_APP_API_URL + "/api/our/offices/" + lang)
            .then(res => res.json())
            .then(res => setData(res.offices));

        fetch(process.env.REACT_APP_API_URL + "/api/home/sliders")
            .then(res => res.json())
            .then(res => setSliderData(res.sliders))
    }, [lang]);

    const subscribeAgain = () => {
        let email = window.location.href.split("?subscribe=")[window.location.href.split("?subscribe=").length - 1];

        fetch(process.env.REACT_APP_API_URL + "/api/subscribers/subscribers", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({email: email})
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    setAgainSubscribed(true)
                }
            })
    };


    const [data, setData] = useState('');


    return (
        <div className='homeee'>

            {popupOpen ? <div className='unsubscribe__popup'>
                <div className='unsubscribe__flex'>
                    <div>
                        {!againSubscribed ?
                            <p>{localStorage.getItem("language") === "am" ? "Դուք ապաբաժանորդագրվել եք մեր լրահոսից։" : "You have unsubscribed from our newsletter."}</p> :
                            <p>{localStorage.getItem("language") === "am" ? "Դուք հաջողությամբ բաժանորդագրվել եք մեր լրահոսին։" : "You have successfully subscribed to our newsletter."}</p>}

                        {!againSubscribed ? <button class="subscribeBtn"
                                                    onClick={() => subscribeAgain()}>Բաժանորդագրվել</button> : null}
                    </div>
                </div>
                <span onClick={() => setPopupOpen(false)}>&#10006;</span>
            </div> : null}

            <div className="sliderContainer">
                <Slider images={sliderData}/>
            </div>

            <div className="about-us section">
                <h3 className='heading'>{lang === "am" ? "Հայաստանի ներդրումային խորհրդի մասին" : "About IC Armenia"}</h3>

                <div className='about-content container'>
                    {ReactHtmlParser(data["text_" + lang])}
                </div>
            </div>

            <div className='latestNews section'>
                <LatestNews/>
            </div>

            <div className='members section'>
                <h3 className='heading'>{lang === "am" ? "ՄԵՐ ԱՆԴԱՄՆԵՐԸ" : "OUR MEMBERS"}</h3>
                <MemberSlide/>
            </div>

        </div>
    )

}

export default withRouter(Home);
