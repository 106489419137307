import React, {useRef} from "react";
import {Row, Col} from "reactstrap";
import emailInfoImg from "../../assets/emailInfo.PNG";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUs(props) {
    const nameSurnameRef = useRef(null);
    const emailRef = useRef(null);
    const textRef = useRef(null);
    const lang = localStorage.getItem("language");

    const notify = () => toast.info(`${lang === "am" ? "Հաղորդագրությունը հաջողությամբ ուղարկվեց" : "Your message was successfully sent"}`);

    const validationNotify = () => toast.error(`${lang === "am" ? "Խնդրում ենք լրացնել ճիշտ էլ․ հասցե" : "Please, fill in the right email"}`);

    const errorNotify = () => toast.error(`${lang === "am" ? "Փորձեք կրկին" : "Try again"}`);

    function sendEmail(e) {
        e.preventDefault();

        fetch(process.env.REACT_APP_API_URL + "/api/home/questions", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: nameSurnameRef.current.value,
                email: emailRef.current.value,
                question: textRef.current.value
            })
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) notify();

                if (res.error === "Email error") validationNotify();

                if (res.error === "Some error occured") errorNotify();
            }).catch(err => console.log(err));

        e.target.reset();
    }

    return (
        <div className="sendEmailContainer">
            <p className="heading">
                {lang === "am" ? "ուղղեք բիզնեսին վերաբերող ձեր հարցը մեր փորձագետին" : "Ask YOUR QUESTION ABOUT BUSINESS TO OUR EXPERT"}
            </p>

            <hr/>

            <form onSubmit={sendEmail}>
                <Row className="contactUsRow">
                    <Col sm="4" className="forPic">
                        <img
                            style={{width: "100%", height: " 397px", objectFit: "cover"}}
                            src={emailInfoImg}
                         alt={"email Info"}/>
                    </Col>
                    <Col>
                        <div className="forEmail">
                            <Row>
                                <Col sm={12} md={6}>
                                    <input
                                        type="text"
                                        style={{width: "100%"}}
                                        placeholder={lang === "am" ? "Անուն Ազգանուն" : "Name Surname"}
                                        name="name"
                                        ref={nameSurnameRef}
                                        required
                                    />
                                </Col>
                                <Col sm={12} md={6}>
                                    <input
                                        style={{width: "100%"}}
                                        type="email"
                                        placeholder={lang === "am" ? "էլ. փոստ" : "Email"}
                                        name="email"
                                        ref={emailRef}
                                        required
                                    />
                                </Col>

                                <Col md={12}>
                    <textarea
                        id=""
                        cols="30"
                        rows="10"
                        name="message"
                        ref={textRef}
                        required
                    />

                                    <input
                                        type="submit"
                                        className="sendEmailBtn"
                                        style={{float: props.buttonSide ? props.buttonSide : "left"}}
                                        value={lang === "am" ? "Ուղարկել" : "Submit"}
                                    />
                                </Col>
                            </Row>


                        </div>
                    </Col>
                </Row>
            </form>

            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}
