import React, {useState, useEffect} from 'react'
import { getUrlPath } from '../../helpers/getUrlPath';
import "react-modal-video/scss/modal-video.scss";
import './Useful.css';

import ReactHtmlParser from 'react-html-parser';

const WebinarsAndVideos = props => {
  const [speakers, setSpeakers] = useState([]);
  const lang = localStorage.getItem("language");

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/helpfull/webinars/" + lang)
    .then(res => res.json())
    .then(res => setSpeakers(res.webinars))
  }, [lang]);

  getUrlPath(props);
  


  return (
    <>

      <div className='webinarsSection'>
        {speakers.length ? speakers.map((speaker, key) => {
          return (
            <div className="vebinar_container" key={key}>
              <div className="vebinar_item">
                <div>
                  <img class="vebinar_image" src={process.env.REACT_APP_IMAGE_URL + "/helpfull_webinars/" + (lang === "am" ? speaker.img_am : speaker.img_en)} />
                </div>

                <div className="vebianr_person_item">
                  <p className="vebinar_name">{speaker["name_" + lang]}</p>
                  <p className="vebinar_position">{speaker["position_" + lang]}</p>
                </div>

                <button className="vebinar_btn">
                    <a href={speaker["embed_" + lang]} target="_blank" rel="noreferrer">
                      <i class="fa fa-play" aria-hidden="true" style={{color: "rgb(221, 122, 117)"}}></i>
                    </a>
                  </button>
              </div>
              <p className="vebinar_text">{ReactHtmlParser(speaker["text_" + lang])}</p>
            </div>
          );
        }) : null}
      </div>
    </>
  )
}
export default WebinarsAndVideos
