import React, {useState, useEffect} from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'
import './AboutUs.css'
import ReactHtmlParser from 'react-html-parser';

import resume1 from "../../assets/resumes/Hovsep Patvakanyan.pdf";
import resume2 from "../../assets/resumes/Hayk Malkhasyan.pdf";
import resume3 from "../../assets/resumes/Davit Aghajanyan.pdf";
import resume4 from "../../assets/resumes/Ruben Sarukhanyan.pdf";
import resume5 from "../../assets/resumes/Hasmik_Tonapetyan.pdf";
import resume6 from "../../assets/resumes/Kamaryan Viktoria.pdf";
import resume7 from "../../assets/resumes/Heghine_Grigoryan.pdf";
import resume8 from "../../assets/resumes/CV_L.Mesrobyan.pdf";

export default function AboutUs(props) {

    getUrlPath(props);
    const [data, setData] = useState([]);
    const lang = localStorage.getItem("language");

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/api/our/staff/' + lang)
            .then(res => res.json())
            .then(res => {
                const items = res.staff;
                if (items.length > 0) {
                    const newItems = [...items]; // Clone the array
                    const firstItem = newItems.shift(); // Remove the first item
                    newItems.push(firstItem); // Add the first item to the end
                    setData(newItems)
                }
            })
            .catch(err => console.log(err))
    }, []);

    const memberInfo = [
        {name:"Hovsep Patvakanyan", resume: resume1 , linkedin: "//www.linkedin.com/in/seppatvakanian/"},
        {name:"Hayk Malkhasyan", resume: resume2 , linkedin: "//www.linkedin.com/in/hayk-malkhasyan-069b8042/"},
        {name:"Davit Aghajanyan", resume: resume3 , linkedin: "//www.linkedin.com/in/davit-aghajanyan-362837247/"},
        // {name:"Ruben Sarukhanyan", resume: resume4 , linkedin: "//www.linkedin.com/in/ruben-sarukhanyan-612b7214/"},
        {name:"Hasmik Tonapetyan.", resume: resume5 , linkedin: "//www.linkedin.com/in/hasmik-tonapetyan/"},
        {name:"Kamaryan Viktoria", resume: resume6 , linkedin: "//www.linkedin.com/in/viktoria-kamaryan-15580a245/"},
        {name:"Heghine Grigoryan.", resume: resume7 , linkedin: "//www.linkedin.com/in/heghine-s-grigor/" },
        {name:"Lilit Mesrobyan.", resume: resume8 , linkedin: "//www.linkedin.com/in/lilit-mesrobyan-b66206ab/" }
    ];


    return (
        <>
            <div className='team container'>

                <div className="section">

                    <h1 className="page-title">{lang === "am" ? "Մեր անձնակազմը" : "Our team"}</h1>

                    <h5>{lang === "am" ? "IC Armenia-ի փորձագետների թիմն ունի երկու տասնամյակից ավել տեղական և միջազգային փորձ բիզնես միջավայրի բարելավմանն ուղղված օրենսդրական բարեփոխումների ոլորտում:"
                        : "Experts of IC Armenia bring over 2 decades of local and international experience in the field of legislative reforms aimed at improving the business environment"}</h5>

                    <div className="card-group">

                        {data.length ? data.map((item, key) => (

                            <div className="col-lg-4 col-md-6 col-sm-12 col-12 card-container">


                                <div className="card item" key={key}>
                                    <img className="card-img-top"
                                         src={process.env.REACT_APP_IMAGE_URL + `/our_staff/` + item.img}
                                         alt={"About us"}/>
                                    <div className="card-body">

                                        <div style={{width: '100%'}}>
                                            <div className="flex justify-content-between">
                                                <p className='card-text name'>{item["name_" + lang]}</p>
                                                <div className={'flex'}>
                                                    <a href={memberInfo[key].resume} download className={'resume-link'}>
                                                        resume
                                                        <i className="fas fa-download"/>
                                                    </a>
                                                    <a href={memberInfo[key].linkedin} target="_blank" rel="noreferrer"
                                                       className="fab fa-linkedin"/>
                                                </div>
                                            </div>
                                            <p className='position card-text'>{ReactHtmlParser(item["position_" + lang])}</p>


                                            <p className='description card-text'/>

                                            <a className="email card-text" href={`mailto: ${item['text_' + lang]}`}>
                                                {item["text_" + lang]}
                                            </a>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        )) : null}
                    </div>
                </div>
            </div>
        </>
    )
}
