import React, {useState, useEffect} from 'react'
import {Col} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import {getUrlPath} from '../../helpers/getUrlPath'
import './SME.css';

export default function MeetingsAndProtocols(props) {
    const [apiData, setApiData] = useState([]);
    const lang = localStorage.getItem("language");

    //Api Call
    useEffect(function () {
        fetch(process.env.REACT_APP_API_URL + "/api/pmdz/reports/" + lang)
            .then(res => res.json())
            .then(res => setApiData(res.reports))
            .catch(err => console.log(err))
    }, [lang]);

    getUrlPath(props)


    const formatDate = date => {
        date = date.split("-");
        var month = date[1];

        if (month === '10') {
            month = lang === "am" ? "Հոկտեմբեր" : "October";
        } else if (month === '11') {
            month = lang === "am" ? "Նոյեմբեր" : "November";
        } else if (month === '12') {
            month = lang === "am" ? "Դեկտեմբեր" : "December";
        } else if (month === '01') {
            month = lang === "am" ? "Հունվար" : "January";
        } else if (month === '02') {
            month = lang === "am" ? "Փետրվար" : "February";
        } else if (month === '03') {
            month = lang === "am" ? "Մարտ" : "March";
        } else if (month === '04') {
            month = lang === "am" ? "Ապրիլ" : "April";
        } else if (month === '05') {
            month = lang === "am" ? "Մայիս" : "May";
        } else if (month === '06') {
            month = lang === "am" ? "Հունիս" : "June";
        } else if (month === '07') {
            month = lang === "am" ? "Հուլիս" : "July";
        } else if (month === '08') {
            month = lang === "am" ? "Օգոստոս" : "August";
        } else if (month === '09') {
            month = lang === "am" ? "Սեպտեմբեր" : "September";
        }

        return month + " " + date[2] + ", " + date[0];
    }

    return (
        <>
            <div className='meetings'>

                <div className="section">

                    <h1 className="page-title container"> {lang === "am" ? "Խորհրդի նիստերը" : "Council Meetings"}</h1>

                    {apiData.length ? apiData.map((item, key) => {
                        return (
                            <a target="_blank" rel="noreferrer"
                               href={process.env.REACT_APP_IMAGE_URL + `/pmdz_reports/` + item["pdf_" + lang]}
                               className={`chess-list${apiData.length - 1 === key ? " chess-list__remove_border" : ""}`}
                               key={key}>

                                <Col className={"container flex"}>

                                <Col sm='12' md="3">
                                    <p className='protocolDate'>{formatDate(item.date)}</p>
                                </Col>

                                <Col sm='12' md="9" lg="6">
                                    <h6 className='protocolText'>{item["title_" + lang]}</h6>

                                </Col>
                                <Col sm='12' md="12" lg="3" className='protocolText'>{ReactHtmlParser(item["text_" + lang])}</Col>

                                </Col>

                            </a>
                        )
                    }) : null}
                </div>
            </div>
        </>
    )
}
