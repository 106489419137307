import React, {useState} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
} from 'reactstrap';
import './Navbar.css'
import {Link, NavLink} from 'react-router-dom';
import {withRouter} from "react-router";

import NavbarLogo from '../../../assets/NavbarLogo.png';
import {useLanguage} from '../../LanguageProvider/LanguageProvider';

const Example = props => {
    const [isOpen, setIsOpen] = useState(false);

    const {location} = props;

    const toggle = () => setIsOpen(!isOpen);

    const toggleMobile = () => window.innerWidth <= 770 ? setIsOpen(!isOpen) : null;

    const lang = localStorage.getItem("language");

    const {newsAmUrl, newsEnUrl} = useLanguage();

    const changeLang = lang => {
        let currentUrl = decodeURIComponent(location.pathname);
        let section;
        let page;
        let language;

        currentUrl = currentUrl.split('/');

        if (currentUrl[currentUrl.length - 1] === "") {
            currentUrl.pop();
        }

        if (currentUrl[currentUrl.length - 1] === "am") {
            return "/en";
        }

        if (currentUrl[currentUrl.length - 1] === "en") {
            return "/am";
        }

        if (currentUrl[currentUrl.length - 1] === "FAQ") {
            currentUrl[currentUrl.length - 2] = currentUrl[currentUrl.length - 2] === "am" ? "en" : "am";
            return currentUrl.join("/");
        }

        language = currentUrl[currentUrl.length - 3];
        section = currentUrl[currentUrl.length - 2];
        page = currentUrl[currentUrl.length - 1];

        if (localStorage.getItem("language") === "am") {
            if (language === "am") {
                if (!newsEnUrl && section === "նորություններ") {
                    language = "am";
                } else {
                    language = "en";
                }
            }

            if (section === "Նախագծեր-բարեփոխումներ") {
                section = "Projects-reforms";
            } else if (section === "ՓՄՁ-Խորհուրդ") {
                section = "SME-Council";
            } else if (section === "նորություններ") {
                if (!newsEnUrl) {
                    section = "նորություններ";
                } else {
                    section = "news";
                }
            } else if (section === "Օգտակար-նյութեր") {
                section = "Useful-materials";
            } else if (section === "Գենդերային-անկյուն") {
                section = "Gender-Corner";
            } else if (section === "Մենք") {
                section = "We";
            } else if (section === "հատվածներ") {
                section = "sections";
            }

            if (page === "Առաքելություն-կառուցվածք") {
                page = "Mission-Structure";
            } else if (page === "Նիստեր-արձանագրություններ") {
                page = "Meetings-minutes";
            } else if (page === "Դիմեք-մեզ") {
                page = "Report-business-issue";
            } else if (page === "Ընթացիկ-նախագծեր") {
                page = "Current-projects";
            } else if (page === "Իրականացված-ծրագրեր") {
                page = "Delivered-projects-reforms";
            } else if (page === "Բիզնես-ուղեցույցներ") {
                page = "Business-Guidelines";
            } else if (page === "Հետազոտություններ") {
                page = "Researches";
            } else if (page === "Իրավական-ակտեր") {
                page = "Legal-acts";
            } else if (page === "Վեբինարներ-տեսանյութեր") {
                page = "Webinars-videos";
            } else if (page === "Գենդերային-հավասարությունը") {
                page = "Gender-equality";
            } else if (page === "Գենդերային-ուղղորդում") {
                page = "Gender-mainstreaming";
            } else if (page === "Հաջողության-պատմություններ") {
                page = "Success-stories";
            } else if (page === "անձնակազմը") {
                page = "our-team";
            } else if (page === "գրասենյակը") {
                page = "office";
            } else if (page === "Հաշվետվություններ") {
                page = "Reports";
            } else if (page === "Գործողությունների-ծրագիրը") {
                page = "Action-plan";
            } else if (page === "նորությունների-ամսաթիվ") {
                if (decodeURIComponent(window.location.href).includes("?month")) {
                    page = "news-date" + "?" + decodeURIComponent(window.location.href).split("?")[1]
                } else {
                    page = "news-date"
                }
            }

        } else {
            if (language === "en") {
                language = "am";
            }

            if (section === "Projects-reforms") {
                section = "Նախագծեր-բարեփոխումներ";
            } else if (section === "SME-Council") {
                section = "ՓՄՁ-Խորհուրդ";
            } else if (section === "news") {
                section = "նորություններ";
            } else if (section === "Useful-materials") {
                section = "Օգտակար-նյութեր";
            } else if (section === "Gender-Corner") {
                section = "Գենդերային-անկյուն";
            } else if (section === "We") {
                section = "Մենք";
            } else if (section === "sections") {
                section = "հատվածներ";
            }

            if (page === "Mission-Structure") {
                page = "Առաքելություն-կառուցվածք";
            } else if (page === "Meetings-minutes") {
                page = "Նիստեր-արձանագրություններ";
            } else if (page === "Report-business-issue") {
                page = "Դիմեք-մեզ";
            } else if (page === "Current-projects") {
                page = "Ընթացիկ-նախագծեր";
            } else if (page === "Delivered-projects-reforms") {
                page = "Իրականացված-ծրագրեր";
            } else if (page === "Business-Guidelines") {
                page = "Բիզնես-ուղեցույցներ";
            } else if (page === "Researches") {
                page = "Հետազոտություններ";
            } else if (page === "Legal-acts") {
                page = "Իրավական-ակտեր";
            } else if (page === "Webinars-videos") {
                page = "Վեբինարներ-տեսանյութեր";
            } else if (page === "Gender-equality") {
                page = "Գենդերային-հավասարությունը";
            } else if (page === "Gender-mainstreaming") {
                page = "Գենդերային-ուղղորդում";
            } else if (page === "Success-stories") {
                page = "Հաջողության-պատմություններ";
            } else if (page === "our-team") {
                page = "անձնակազմը";
            } else if (page === "office") {
                page = "գրասենյակը";
            } else if (page === "Reports") {
                page = "Հաշվետվություններ";
            } else if (page === "Action-plan") {
                page = "Գործողությունների-ծրագիրը";
            } else if (page === "news-date") {
                if (decodeURIComponent(window.location.href).includes("?month")) {
                    page = "նորությունների-ամսաթիվ" + "?" + decodeURIComponent(window.location.href).split("?")[1]
                } else {
                    page = "նորությունների-ամսաթիվ";
                }
            }
        }

        currentUrl[currentUrl.length - 3] = language;
        currentUrl[currentUrl.length - 2] = section;
        currentUrl[currentUrl.length - 1] = page;

        if (currentUrl[2] === "news") {
            if (!newsEnUrl) {
                currentUrl[3] = newsAmUrl;
            } else {
                currentUrl[3] = newsEnUrl;
            }
        }

        if (currentUrl[2] === "նորություններ") {
            currentUrl[3] = newsAmUrl;
        }

        return currentUrl.join("/");
    };

    return (
        <div className="bg-light">
            <Navbar color="light" light expand="lg" className={"container"}>

                <NavLink className="navbar-brand" to={"/" + lang}>
                    <img className='nav-logo' src={NavbarLogo} alt={"IC Armenia office logo"}/>
                </NavLink>

                <NavbarToggler className='bg-light' onClick={toggle}/>

                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                        <NavItem>
                            <Link className="nav-link" to="#">
                                <div className="dropdown">
                                    <button className="dropbtn">
                                        IC Armenia
                                    </button>
                                    <div className="dropdown-content">

                                        <Link onClick={toggleMobile}
                                              to={`/${lang === "am" ? "am/ՓՄՁ-Խորհուրդ/Նիստեր-արձանագրություններ" : "en/SME-Council/Meetings-minutes"}`}>
                                            {lang === "am" ? "Խորհրդի նիստերը" : "Council Meetings"}
                                        </Link>

                                        <hr className="navbar_hr"/>

                                        <Link onClick={toggleMobile}
                                              to={`/${lang === "am" ? "am/Մենք/Հաշվետվություններ" : "en/We/Reports"}`}>
                                            {lang === "am" ? "Տարեկան Հաշվետվություններ" : "Annual Reports"}
                                        </Link>
                                        <hr className="navbar_hr"/>

                                        <Link onClick={toggleMobile}
                                              to={`/${lang === "am" ? "am/Մենք/անձնակազմը" : "en/We/our-team"}`}>
                                            {lang === "am" ? "Մեր անձնակազմը" : "Team"}
                                        </Link>
                                        <hr className="navbar_hr"/>

                                        <Link onClick={toggleMobile}
                                              to={`/${lang === "am" ? "am/ՓՄՁ-Խորհուրդ/Առաքելություն-կառուցվածք" : "en/SME-Council/Mission-Structure"}`}>
                                            {lang === "am" ? "ՓՄՁ Զարգացման Խորհուրդ" : " SME Development Council"}
                                        </Link>

                                        <hr className="navbar_hr"/>

                                        <Link onClick={toggleMobile}
                                              to={`/${lang === "am" ? "am/Գենդերային-անկյուն/Գենդերային-հավասարությունը" : "en/Gender-Corner/Gender-equality"}`}>
                                            {lang === "am" ? "Գենդերային հավասարություն" : "Gender Equality"}
                                        </Link>

                                    </div>
                                </div>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link"
                                  to={`/${lang === "am" ? "am/Նախագծեր-բարեփոխումներ/Իրականացված-ծրագրեր" : "en/Projects-reforms/Delivered-projects-reforms"}`}>
                                <div className="dropdown">
                                    <button className="dropbtn">
                                        {lang === "am" ? "Բարեփոխումներ" : "Reforms"}
                                    </button>

                                </div>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link"
                                  to={`/${lang === "am" ? "am/հատվածներ/նորությունների-ամսաթիվ" : "en/sections/news-date"}`}>
                                <div className="dropdown">
                                    <button className="dropbtn">
                                        {lang === "am" ? "Նորություններ" : "News"}
                                    </button>

                                </div>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link"
                                  to={`/${lang === "am" ? "am/Օգտակար-նյութեր/Բիզնես-ուղեցույցներ" : "en/Useful-materials/Business-Guidelines"}`}>
                                <div className="dropdown">
                                    <button className="dropbtn">
                                        {lang === "am" ? "Օգտակար նյութեր" : "Useful materials"}
                                    </button>

                                </div>
                            </Link>
                        </NavItem>
                        <NavItem className={"lang"}>
                            <div className="dropdown">
                                <button className="dropbtn">  {lang === "am" ? "Հայերեն" : "English"}
                                    <i className="fas fa-caret-down"/>
                                </button>

                                <div className="dropdown-content lang_dropdown">
                                    <a href={lang === "am" ? changeLang("en") : changeLang("am")}>{lang === "en" ? "Հայերեն" : "English"}</a>
                                </div>
                            </div>
                        </NavItem>
                    </Nav>
                </Collapse>

            </Navbar>
        </div>
    );
};

export default withRouter(Example);
