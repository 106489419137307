import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';


if (window.location.pathname === "/") {
    window.location.href = process.env.REACT_APP_URL + 'en';
}

if (window.location.href.split("/").includes("en")) {
    localStorage.setItem("language", "en");
} else {
    localStorage.setItem("language", "am");
}

ReactDOM.render(<App />, document.getElementById('root'));
