import React, {useState, useEffect} from 'react'
import {getUrlPath} from '../../helpers/getUrlPath'
import ReactHtmlParser from 'react-html-parser'

export default function Office (props) {
  const [data, setData] = useState('');
  const lang = localStorage.getItem("language");

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/our/offices/" + lang)
    .then(res => res.json())
    .then(res => setData(res.offices))
  }, []);

  getUrlPath(props)
  


  return (
    <>

      <div className='companyContainer'>
        {ReactHtmlParser(data["text_" + lang])}
      </div>
    </>
  )
}
