import React, {useState, useEffect} from 'react'
import { getUrlPath } from '../../helpers/getUrlPath'
import { Row, Col, Collapse, CardBody } from 'reactstrap';

import LeaveASuggestion from './LeaveASuggestion'

import ReactHtmlParser from 'react-html-parser';

import ModalImage from "react-modal-image";

import PDFimage from '../../assets/PDF.png'

export default function GoingProjects (props) {
  const [projects, setProjects] = useState([]);
  const [isOpen, setIsOpen] = useState(-1);

  const [moreLess, setMoreLess] = useState(false);

  const lang = localStorage.getItem("language");
  const { path_id, path_controller } = getUrlPath(props)

  getUrlPath(props)

  //Api Call
  useEffect(function () {
    fetch(process.env.REACT_APP_API_URL + "/api/programs/ongoing/" + lang)
        .then(res => res.json())
    .then(res => setProjects(res.ongoing))
  }, []);

  const toggle = key => key === isOpen ? setIsOpen(-1) : setIsOpen(key)

  const getTextLength = apiText => {
    var element = document.createElement('div');
    element.innerHTML = apiText;
    var text = element.innerText || element.textContent;

    return text.trim().length;
  }

            return (
    <>
      <div className='urlHeading'>
        <p>{lang === "am" ? "հիմնական" : "main"}</p> <i className="fa fa-arrow-right" aria-hidden="true"></i>  {path_id} <i className="fa fa-arrow-right" aria-hidden="true"></i>  <span style={{ color: '#a2babc' }}>{path_controller}</span>
      </div>

      <div className='gpBox' style={{overflow:'hidden'}}>
        {projects.length ? projects.map((item, key) => {
                    return (
            <div className="going_project_container" key={key}>
              <div className="going_project_img_container">
                  <img src={`${process.env.REACT_APP_IMAGE_URL}/programs_ongoing/${(lang === "am" ? item.img_am : item.img_en)}`} />

                  {item["text_" + lang] ? <div className={moreLess ? "going_project_paragraph_container going_project_paragraph_container--more" : "going_project_paragraph_container"}>
                    {ReactHtmlParser(item["text_" + lang])}
                  </div> : <div className={"going_project_paragraph_container going_project_paragraph_container--hide"}>
                    {ReactHtmlParser(item["text_" + lang])}
                  </div>}

                  {!moreLess && getTextLength(item["text_" + lang]) > 520 ? <span className="suggentSeeMoreLess" onClick={() => setMoreLess(true)}>{(lang === "am" ? "Տեսնել ավելին" : "See More")}</span> : null}

                  {moreLess ? <span className="suggentSeeMoreLess" onClick={() => setMoreLess(false)}>{lang === "am" ? " Տեսնել քիչ" : " See Less"}</span> : null}
        </div>

              <button className='going_project_paragraph_btn' style={{fontFamily: 'MontserratarmLight'}} onClick={() => toggle(key)} type="button">
                {lang === "am" ? "Թողնել մեկնաբանություն" : "Leave a comment"}
              </button>

              {/* <button className='going_project_paragraph_btn' style={{fontFamily: 'MontserratarmLight'}} onClick={() => toggle(key)} type="button">{lang === "am" ? "Թողնել մեկնաբանություն" : "Leave a comment"}</button> */}

              <Collapse isOpen={isOpen === key ? true : false}>
                <CardBody className='leaveSuggestionCollapse'>
                  <LeaveASuggestion />
                </CardBody>
              </Collapse>

              <div className="videos_image_container">
                  {item.media.length ? item.media.map((med, key) => {
                    return (
                      <React.Fragment key={key}>
                        {med.type === "image" ? <ModalImage
                                    className="modal-image-meetings"
                                    hideDownload={true}
                                    hideZoom={true}
                                    small={process.env.REACT_APP_IMAGE_URL + "/programs_ongoing/" + med.file}
                                    large={process.env.REACT_APP_IMAGE_URL + "/programs_ongoing/" + med.file}
                                  /> : med.type === "application" ? <div>
                                    <a href={`${process.env.REACT_APP_IMAGE_URL}/programs_ongoing/${med.file}`}><img src={PDFimage} width="70" height="70" /></a>
                                  </div> : <div>
                                  <video controls src={process.env.REACT_APP_IMAGE_URL + "/programs_ongoing/" + med.file} width="200" height="100"></video>
                                </div>}
                </React.Fragment>
                    );
                  }) : null}
          </div>
        </div>
          )
        }) : null}
      </div>

      </>
  )
}
