import React, {useState, useEffect} from 'react'
import {Row, Col} from 'reactstrap';
import {getUrlPath} from '../../helpers/getUrlPath'
// import './SME.css';

import ReactHtmlParser from 'react-html-parser';
import {Link} from "react-router-dom";

export default function Reports(props) {
    const [allReports, setAllReports] = useState([]);
    const lang = localStorage.getItem("language");

    //Api Call
    useEffect(function () {
        fetch(process.env.REACT_APP_API_URL + "/api/our/reports/" + lang)
            .then(res => res.json())
            .then(res => setAllReports(res.reports))
    }, []);

    getUrlPath(props)


    return (
        <>

            <div className='reportContainer'>

                <div className="section">

                    <h1 className=" page-title container">  {lang === "am" ? "Տարեկան Հաշվետվություններ" : "Annual Reports"}</h1>

                    {allReports.length ? allReports.map((item, key) => {
                        return (
                            <a target="_blank" rel="noreferrer"
                               href={process.env.REACT_APP_IMAGE_URL + `/our_reports/` + (lang === "am" ? item.pdf_am : item.pdf_en)}
                               className={`row chess-list`} key={key}>
                                <Col className={"container"}>
                                    <h6 className='protocolText'>{ReactHtmlParser(item["text_" + lang] + ' ' + item.number)} </h6>
                                </Col>


                            </a>
                        )
                    }) : null}
                </div>
            </div>
        </>
    )
}
